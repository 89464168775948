.rate-wrapper {
    text-align: center;
    margin: 64px auto -40px;
}

.rate-wrapper__title {
    margin-bottom: 24px;
    color: #4d4d4d;
    font: 500 16px/normal Roboto, sans-serif;
    font-size: calc(14px + (16 - 14) * (100vw - 700px) / (1920  - 700));
}

.rating-list {
    justify-content: space-between;
    max-width: 460px;
    margin: 0 auto;
    grid-gap: 10px;
    cursor: pointer;
}

.rating-list__item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rating-list__item:hover {
    .rating-emojis {
        background: #25292E;
    }

    .rating-emoji-label {
        color: #25292E;
    }
}

.rating-emojis {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    margin-bottom: 8px;
    border-radius: 12px;
    background: rgba(37, 41, 46, 0.8);
    transition: background 500ms;
}

.rating-emojis img {
    width: 36px;
    height: 36px;
}

.rating-emoji-label {
    color: rgba(37, 41, 46, 0.8);
    font: 500 16px/normal Roboto, sans-serif;
    transition: color 500ms;
    font-size: calc(14px + (16 - 14) * (100vw - 700px) / (1920  - 700));
}

@media screen and (max-width: 700px) {
    .rate-wrapper {
        margin: 0;
        padding: 24px 16px 0;
    }

    .rate-wrapper__title {
        margin-bottom: 16px;
    }

    .rating-list {
        max-width: 344px;
    }

    .rating-emojis {
        width: 44px;
        height: 44px;
    }

    .rating-emojis img {
        width: 30px;
        height: 30px;
    }
}

