.transaction-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: 500 24px/normal Roboto, sans-serif;
    font-size: calc(18px + (24 - 18) * (100vw - 700px) / (1920  - 700));
}

.transaction-status.message {
    padding: 8px;
    font-weight: 400;
    font-size: 16px;
}

.transaction-status-img-wrapper {
    position: relative;
    align-self: center;
    width: 74px;
    height: 74px;
    margin: -52px 0 40px ;
    border-radius: 50%;
    background: #F3F4F7;
    border: 10px solid #F3F4F7;
    box-sizing: content-box;
}

.transaction-status-img-wrapper::before,
.transaction-status-img-wrapper::after {
    content: '';
    width: 20px;
    height: 20px;
    background: #25292E;
    position: absolute;
    top: 42px;
}

.transaction-status-img-wrapper::before {
    left: -26px;
    border-top-right-radius: 20px;
    box-shadow: 1px -7px 0 #F3F4F7;
}

.transaction-status-img-wrapper::after {
    right: -26px;
    border-top-left-radius: 20px;
    box-shadow: -1px -7px 0 #F3F4F7;
}

.transaction-status img {
    width: auto;
    height: auto;
}

.message-info {
    margin-top: 8px;
    font: 400 16px/normal Roboto, sans-serif;
    font-size: calc(14px + (16 - 14) * (100vw - 700px) / (1920  - 700));
    text-align: center;
}

.error {
    color: #F5222D;
}

.success {
    color: #24C824;
}

.inProcess {
    color: white;
}

@media screen and (max-width: 700px) {
    .transaction-status-img-wrapper {
        margin-bottom: 24px;
    }
}
