.nfp-header{
    height: 97px;
    display: flex;
    align-items: center;
    /* background-color: #161415; */
    background: #ebfffc;
    padding-left: 62px;
}

.not-found-wrapper{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page__image{
    margin: 0 auto 50px;
    text-align: center;
    padding: 0;
}

.nfp-footer {
    width: 100%;
    height: 97px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ebfffc;
    text-align: center;
}

.nfp-footer span {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    color: #000000;
}

.nfp-message p{
    font: 500 14px/normal Roboto,sans-serif;
    text-transform: uppercase;
    text-align: center;
    color: #00a1af;
    margin: 0 auto 24px;
}

.nfp-btn{
    width: 210px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 400 14px/normal Roboto,sans-serif;
    text-transform: uppercase;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #00a1af;
    background: #00a1af;
    box-shadow: none;
    cursor: pointer;
    opacity: 1;
    margin: 0 auto;
}

.nfp-btn a{
    display: block;
    color: #ffffff;
    text-decoration: none;
}


@media screen and (max-width: 576px){
    .page__image img {
        width: 80%;
    }
}
